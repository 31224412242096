import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import "./styles.scss";
import HeaderMenuBar from "./HeaderMenuBar";

import { useAuthState } from '@/contexts/AuthContext';

import HelmetHeadContent from "../HelmetHeadContent";

import logo from "@/assets/images/prestamo-personal-online-instante-doctorsol.svg";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { authZoneLink } = useAuthState();
  const { pathname } = useLocation();

  const toggleShowMenu = () => {
    setShowMenu(prev => !prev);
  };

  useEffect(() => {
    if (showMenu) setShowMenu(false);
  }, [pathname]);

  return (
    <>
      <HelmetHeadContent />
      
      <header className={showMenu ? "active" : ""}>
        <div className="container-page">      
          <HeaderMenuBar show={showMenu} />
          <div className={`part-menu ${showMenu ? "active" : ""}`}>  
            <div className="header-left">
              <a
                href={authZoneLink}
                target="_blank"
                rel="noreferrer"
                className={`orange-button mobile ${showMenu ? "hidden" : ""}`}
                id="enter-lk"
              >
                Acceder
              </a>
              <div className="menu">
                <div
                  id="nav-button"
                  className={`nav-bar-icon ${showMenu ? "active" : ""}`}
                  onClick={() => toggleShowMenu()}
                >
                  <div className="nav-bar-icon_line"></div>
                  <div className="nav-bar-icon_line"></div>
                  <div className="nav-bar-icon_line"></div>
                </div>
              </div>                          
              <Link to="/" className="header-location">
                <img alt="logo" src={logo} />
              </Link>
            </div>

            <div className="header-right">
              <div>
                <a
                  href={authZoneLink}
                  target="_blank"
                  rel="noreferrer"
                  className="orange-button"
                  id="enter-lk"
                >
                  Acceder
                </a>
              </div>        
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
